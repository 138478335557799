import { lazy } from "react";
import { Navigate } from "react-router-dom";

import ModuleSuspense from "./shared/ModuleSuspense";
import LayoutSuspense from "./shared/LayoutSuspense";
const Agreement = lazy(() => import("./pages/policies/agreement"));

// Layouts
const AuthLayout = lazy(() => import("./layouts/Auth"));
const DashboardLayout = lazy(() => import("./layouts/Dashboard"));

// Auth components
const SignIn = lazy(() => import("./pages/auth"));

// Dashboard components
const Dashboard = lazy(() => import("./pages/modules/dashboard"));

// Directory modules
const Users = lazy(() => import("./pages/modules/directory/users"));
const UserProfile = lazy(() =>
  import("./pages/modules/directory/users/Profile")
);
const Units = lazy(() => import("./pages/modules/directory/units"));

// App modules
const Services = lazy(() => import("./pages/modules/apps/services"));
const Subscriptions = lazy(() => import("./pages/modules/apps/subscriptions"));
const SubscriptionDetails = lazy(() =>
  import("./pages/modules/apps/subscriptions/details")
);
const SwitchPlan = lazy(() => import("./pages/modules/apps/services/switch"));
const PurchasePlan = lazy(() =>
  import("./pages/modules/apps/services/purchase")
);
const ThirdPartyApplications = lazy(() =>
  import("./pages/modules/apps/third-party")
);
// Billing modules
const Transactions = lazy(() => import("./pages/modules/billing/transactions"));
const Accounts = lazy(() => import("./pages/modules/billing/accounts"));

//Settings modules
const Organization = lazy(() =>
  import("./pages/modules/settings/organization")
);
const AuthorizationManagement = lazy(() =>
  import("./pages/modules/settings/authorization")
);

export const authenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <ModuleSuspense>
            <Dashboard />
          </ModuleSuspense>
        ),
      },
      { path: "*", element: <Navigate to="/dashboard" /> },
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },

  {
    path: "/directory",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/directory/users",
        element: (
          <ModuleSuspense>
            <Users />
          </ModuleSuspense>
        ),
      },
      {
        path: "users/:id",
        element: (
          <ModuleSuspense>
            <UserProfile />
          </ModuleSuspense>
        ),
      },
      {
        path: "units",
        element: (
          <ModuleSuspense>
            <Units />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/apps",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "subscriptions",
        element: (
          <ModuleSuspense>
            <Subscriptions />
          </ModuleSuspense>
        ),
      },
      {
        path: "subscriptions/:id/:sku_id",
        element: (
          <ModuleSuspense>
            <SubscriptionDetails />
          </ModuleSuspense>
        ),
      },
      {
        path: "services",
        element: (
          <ModuleSuspense>
            <Services />
          </ModuleSuspense>
        ),
      },
      {
        path: "services/switch",
        element: (
          <ModuleSuspense>
            <SwitchPlan />
          </ModuleSuspense>
        ),
      },
      {
        path: "services/purchase",
        element: (
          <ModuleSuspense>
            <PurchasePlan />
          </ModuleSuspense>
        ),
      },
      {
        path: "3rd-party",
        element: (
          <ModuleSuspense>
            <ThirdPartyApplications />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/billing",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "payments",
        element: (
          <ModuleSuspense>
            <Transactions />
          </ModuleSuspense>
        ),
      },
      {
        path: "accounts",
        element: (
          <ModuleSuspense>
            <Accounts />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/settings",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "authorization",
        element: (
          <ModuleSuspense>
            <AuthorizationManagement />
          </ModuleSuspense>
        ),
      },
      {
        path: "organization",
        element: (
          <ModuleSuspense>
            <Organization />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/agreement",
    element: (
      <LayoutSuspense>
        <AuthLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/agreement",
        element: (
          <ModuleSuspense>
            <Agreement />
          </ModuleSuspense>
        ),
      },
    ],
  },
];

export const unauthenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <AuthLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/sign-in",
        element: (
          <ModuleSuspense>
            <SignIn />
          </ModuleSuspense>
        ),
      },
      {
        path: "/",
        element: <Navigate to="/sign-in" />,
      },
      {
        path: "*",
        element: <Navigate to="/sign-in" />,
      },
    ],
  },
];
